import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Relams of Reality",
  "date": "2018-07-19T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Reality that is metaphysically true and ontologically accurate. It is the state of experiencing that "which is real and into which both other reality levels can be resolved". This reality is the highest; it can't be sublated (assimilated) by any other.`}</p>
    <p>{`Consisting of the empirical or pragmatical reality. It is ever-changing over time, thus empirically true at a given time and context but not metaphysically true. It is "our world of experience, the phenomenal world that we handle every day when we are awake". It is the level in which both jiva (living creatures or individual Selfs) and Iswara are true; here, the material world is also true but this is incomplete reality and is sublimatable`}</p>
    <p>{`"Reality based on imagination alone". It is the level of experience in which the mind constructs its reality. Well-known examples of pratibhasika is the imaginary reality such as the "roaring of a lion" fabricated in dreams during one's sleep, and the perception of a rope in the dark as being a snake`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      